.card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bg-primary-gradient {
  background: linear-gradient(45deg, #007bff, #6610f2);
}

.bg-info-gradient {
  background: linear-gradient(45deg, #17a2b8, #20c997);
}

.bg-success-gradient {
  background: linear-gradient(45deg, #28a745, #218838);
}

.bg-warning-gradient {
  background: linear-gradient(45deg, #ffc107, #e0a800);
}

.bg-light-gradient {
  background: linear-gradient(45deg, #f8f9fa, #e9ecef);
}

.table-primary-gradient {
  background: linear-gradient(45deg, #007bff, #6610f2);
  color: white;
}

.table-danger-gradient {
  background: linear-gradient(45deg, #dc3545, #c82333);
  color: white;
}

.hover-focus:hover {
  transform: scale(1.02);
  transition: transform 0.2s ease-in-out;
}

a {
  text-decoration: none !important;
}
