.table-responsive {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
