/* .payments-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
*/
.payments-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: #f8f9fa;
  z-index: 1;
}
/*
.payments-content {
  overflow-y: scroll;
  flex: 1;
  padding: 1rem;
}

.table {
  border: none; 
  width: 100%;
}

.thead-dark {
  background-color: #343a40;
  color: #fff;
} */
